@import "../public/assets/scss/variables";

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: $spacing-size-none;
  font-family: $font-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  height: 100vh;
  box-sizing: border-box;
}

input,
textarea {
  font-family: $font-base;
}

button,
textarea {
  font-family: $font-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

a {
  text-decoration: none;
}
