@font-face {
  font-family: "Nohemi";
  src: local("Nohemi Regular"), local("Nohemi-Regular"),
    url("Nohemi-Regular.woff") format("woff"),
    url("Nohemi-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nohemi";
  src: url("Nohemi-Medium.otf"),
    url("Nohemi-Medium.woff") format("woff"),
    url("Nohemi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nohemi";
  src: url("Nohemi-SemiBold.otf"),
  url("Nohemi-SemiBold.woff") format("woff"),
  url("Nohemi-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nohemi";
  src: url("Nohemi-Bold.otf"),
  url("Nohemi-Bold.woff") format("woff"),
  url("Nohemi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nohemi";
  src: url("Nohemi-ExtraBold.otf"),
  url("Nohemi-ExtraBold.woff") format("woff"),
  url("Nohemi-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

