@import "../../../public/assets/scss/variables";

.footer {
  background: #151413;
  color: #fff;
  padding: 150px 0 100px;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 55px;

    &-top {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-logo {
      max-width: 114px;
      height: 114px;
      flex: 1;

      .logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-menu {
      display: flex;
      justify-content: center;
      gap: 32px;
      flex: 3;

      &-items {
        display: flex;
        gap: 32px;
      }

      &-item {
        transition: background-color 0.4s ease-in-out;
        border-radius: $border-radius-sm;

        a {
          padding: 12px 16px;
          display: block;
          color: $primary-white;
          text-decoration: none;
        }

        &:hover {
          background: $primary-green;
        }
      }
    }

    &-subscribe {
      flex: 1;
      &-button {
        height: 70px;
        width: 266px;
        font-family: "Nohemi", sans-serif;
        font-weight: 500;
      }
    }
  }

  .form-input {
    display: flex;
    align-items: center;
    min-width: 250px;

    &__label {
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-bottom: 33px;
    }

    &__input {
      font-size: 14px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 124, 0.2);
      border-radius: 6px 0 0 6px;
      height: 50px;

    }

    &__button {
      background: #5ECA51;
      border: none;
      border-radius: 0 6px 6px 0;
      min-width: 50px;
      height: 50px;
      cursor: pointer;

      svg {
        fill: #FFFFFF;
        width: 15px;
        height: 15px;
      }
    }

  }

  &__divider {
    background: #FFFFFF;
    opacity: 0.1;
  }

  .divider-mobile {
    display: none;
  }

  &__icon-svg {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;

    &-socials {
      //flex: 1;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      //flex: 3;
      gap: 36px;
      &-mail {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }


  @media (min-width: 1200px) {
    .container {
      width: 90%;
    }
  }

  @media (max-width: 1200px) {
    padding: 50px 0;
    &__content {
      flex-direction: column;

      &-top {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        align-items: flex-end;
        margin-top: 30px;
      }

      &-menu {
        justify-content: flex-end;

        &-items {
          flex-direction: row;
        }
      }

      &-subscribe {
        max-width: 300px;
      }
    }

    &__divider {
      display: none;
    }

    .divider-mobile {
      display: block;
      width: 100%;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 600px) {
    padding: 52px 0;
    &__content {
      margin-bottom: 32px;

      &-top {
        flex-direction: column;
        align-items: flex-start;
      }

      &-bottom {
        align-items: flex-start;
      }

      &-logo {
        width: 114px;
        height: 114px;
        margin: 0 auto 32px;
      }
      &-menu {
        align-self: center;
        margin-top: 32px;
        gap: 4px;
      }
      &-menu-items {
        gap: 4px;
      }

      &-subscribe {
        max-width: none;
        width: 100%;
        &-button {
          height: 50px;
          width: 100%;
        }
      }
    }

    &__info {
      flex-direction: row-reverse;
      &-content {
        &-mail {
          font-size: 14px;
        }
      }
    }

    &__divider {
      display: block;
    }
  }
}
