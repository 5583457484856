.collapsible-section {
  margin-bottom: 54px;

  &__title {
    font-family: "Nohemi", sans-serif;
    font-size: 36px;
    line-height: 24px;
    color: #0A0908;
    display: flex;
    align-self: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__icon {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease-in-out;

    &.rotate {
      transform: rotate(90deg);
    }
  }

  &__text {
    font-size: 24px;
    line-height: 32px;
    color: #0A0908;
    margin-top: 32px;
  }


  @media (max-width: 1200px) {
    &__title {
      font-size: 32px;
      line-height: 32px;
    }
    &__text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
