.mobile-app {
  background: #5ECA51;
  padding: 74px 0;
  box-sizing: border-box;

  &-wrapper {
    display: flex;
    align-items: flex-end;
    height: 100%;
    min-height: 500px;
    position: relative;
  }

  &__content {
    width: 50%;

    &-title {
      font-family: 'Nohemi', sans-serif;
      font-size: 48px;
      line-height: 56px;
      font-weight: 500;
      padding: 45px 0;
      margin-bottom: 60px;
      position: relative;
      background: linear-gradient(90deg, rgba(207, 255, 198, 0.7) 0%, #57B94C 100%);
      br {
        display: none;
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -100%;
        display: block;
        background: #57B94C;
      }

      &::after {
        content: "";
        width: 1000%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1000%;
        display: block;
        z-index: 1;
        background: rgba(207, 255, 198, 0.7);
      }
    }

    &-text {
      font-size: 18px;
      line-height: 24px;
    }

    &-buttons {
      display: flex;
      gap: 20px;
      margin-top: 32px;

      .button {
        min-width: 140px;
        font-weight: 600;
      }
    }

    &-button-icon {
      width: 15px;
      height: 15px;
      margin-left: 4px;
    }
  }

  &__button-transparent {
    border: 1px solid #0A0908;
    color: #0A0908;
  }

  &__video-container {
    width: 50%;

    &-phone-bg {
      width: 418px;
      height: 900px;
      position: absolute;
      right: 0;
      top: -220px;
    }
    .iphone-bg {
      position: relative;
      width: 100%;
      height: 100%;
      &::before {
        content: "";
        display: block;
        background: #000;
        position: absolute;
        top: 4px;
        right: 0;
        left: 5px;
        bottom: 0;
        border-radius: 77px;
        width: calc(100% - 10px);
        height: calc(100% - 18px);
      }
    }

    .iphone {
      width: 100%;
      height: calc(100% - 7px);
    }

    video {
      position: absolute;
      height: calc(100% - 20px);
      width: calc(100% - 10px);
      right: 5px;
      top: 6px;
      border-radius: 74px;
    }
  }

  &__images-wrapper {
    margin-top: 150px;
    display: flex;
    gap: 24px;
    .image-app {
      width: 166px;
      height: 49px;
    }
  }

  @media (max-width: 1200px) {
    &-wrapper {
      min-height: initial;
    }

    &__video-container {
      &-phone-bg {
        height: 600px;
        width: 286px;
        top: -140px;
      }
      .iphone-bg {
        &::before {
          border-radius: 49px;
          height: calc(100% - 16px);
        }
      }

      video {
        border-radius: 49px;
      }
    }

    &__images-wrapper {
      margin-top: 50px;
    }
  }


  @media (max-width: 600px) {
    &-wrapper {
      flex-direction: column;
      justify-content: flex-end;
    }

    &__content {
      width: 100%;
      //margin-top: 190px;
      margin-top: 0;

      &-title {
        margin-bottom: 350px;
        br {
          display: block;
        }
        &::before {
          width: 16px;
          right: -16px;
        }
      }

      &-text {
        font-size: 14px;
        line-height: 22px;
      }
    }

    &__video-container {
      width: 100%;
      text-align: center;

      &-phone-bg {
        height: 478px;
        width: 227px;
        //right: calc(50% - 114px);
        right: 0;
        top: 50px;
      }

      .iphone-bg {
        &::before {
          border-radius: 36px;
          height: calc(100% - 16px);
        }
      }

      video {
        border-radius: 36px;
      }
    }
  }

  @media (max-width: 410px) {
    &__content-title {
      margin-bottom: 220px;
    }
    &__video-container {
      width: 100%;
      text-align: center;

      &-phone-bg {
        height: 352px;
        width: 167px;
        right: 0;
        top: 50px;
      }

      .iphone-bg {
        &::before {
          border-radius: 24px;
          height: calc(100% - 16px);
        }
      }

      video {
        border-radius: 24px;
      }
    }
  }
}
