@import "sizes";

.container {
  margin: 0 auto;
  padding: 0 16px;
  min-width: $min-container-width;
  max-width: $max-container-width;

  &__fluid {
    max-width: 100%;
  }
}

.row {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

.col {
  max-width: 100%;
  width: 100%;
  flex: 1 0 0;
}
.col-1 {
  max-width: calc(100% / 12);
  width: 100%;
}

.col-2 {
  max-width: calc(100% / 12 * 2);
  width: 100%;
}

.col-3 {
  max-width: calc(100% / 12 * 3);
  width: 100%;
}

.col-4 {
  max-width: calc(100% / 12 * 4);
  flex: 1 0 calc(100% / 12 * 4);
  width: 100%;
}

.col-5 {
  max-width: calc(100% / 12 * 5);
  width: 100%;
}

.col-6 {
  max-width: calc(100% / 2);
  width: 100%;
}

.col-7 {
  max-width: calc(100% / 12 * 7);
  width: 100%;
}

.col-8 {
  max-width: calc(100% / 12 * 8);
  width: 100%;
}

.col-9 {
  max-width: calc(100% / 12 * 9);
  width: 100%;
}

.col-10 {
  max-width: calc(100% / 12 * 10);
  width: 100%;
}

.col-11 {
  max-width: calc(100% / 12 * 11);
  width: 100%;
}

.col-12 {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 991px) {
  .row {
    flex-direction: column;
    div {
      max-width: 100%;
    }
  }
}

@media (max-width: 1500px) {
  .container {
    margin: 0 auto;
    min-width: $min-container-width;
    //max-width: 1199px;
  }
}

@media (max-width: 1350px) {
  .container {
    margin: 0 auto;
    min-width: $min-container-width;
    //max-width: 1100px;
  }
}

@media (max-width: 1199px) {
  .container {
    margin: 0 auto;
    min-width: $min-container-width;
    max-width: 1100px;
  }
}
