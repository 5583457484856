@import "../../../../public/assets/scss/variables";

.why-block {
  position: relative;
  top: -100px;
  margin-bottom: -100px;
  overflow: hidden;

  .card {
    padding: 64px 64px 64px 64px;
    background: $primary-green-light;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;


    &__info {
      &-title {
        font-family: 'Nohemi', sans-serif;
        font-size: 48px;
        line-height: 44px;
        font-weight: 500;
        margin-bottom: 24px;
      }

      &-text {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }

      &-form {
        max-width: 330px;
        width: 100%;
      }
    }

    .form-input__input {
      height: 52px;
      margin-bottom: 12px;
    }

    .invite-button {
      width: 100%;
      font-weight: 500;
      font-family: 'Nohemi', sans-serif;
    }
  }

  .container-blocks {
    padding-top: 50px;
  }

  &__wrapper {
    position: relative;
    background: url("../../../../public/assets/images/bg-circles.webp") no-repeat center center;
    margin: 100px 0 120px;
    min-height: 820px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 114px 50px 66px;

    &-title {
      color: $primary-dark;
      font-size: $font-size-xxl;
      line-height: 4.5rem;
      margin-top: 0;
      position: absolute;
      top: -45px;
      left: 0;
    }

    &-image-wrapper {
      position: relative;
      z-index: 5;
    }
  }

  &__text-item {
    position: absolute;

    &-content {
      position: relative;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-style: normal;

      span {
        font-weight: 500;
      }


      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }

  &__list-top-image,
  &__list-bottom-image {
    display: none;
  }

  .text-item1 {
    top: 0;
    right: 10%;
    max-width: 400px;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line1.png") no-repeat center center;
        right: 0;
        bottom: -66px;
      }
    }
  }

  .text-item2 {
    top: 20%;
    left: 2%;
    max-width: 366px;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line2.png") no-repeat center center;
        top: 15px;
        right: -240px;
      }
    }
  }

  .text-item3 {
    top: 20%;
    right: 0;
    max-width: 26%;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line3.png") no-repeat center center;
        right: 46%;
        bottom: -67%;
      }
    }
  }

  .text-item4 {
    top: 42%;
    left: 5%;
    max-width: 31%;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line4.png") no-repeat center center;
        top: -88px;
        right: -34%;
      }
    }
  }

  .text-item5 {
    top: 58%;
    right: 0;
    max-width: 26%;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line5.png") no-repeat center center;
        background-size: contain;
        height: 118px;
        top: -80%;
        left: -55%;
        z-index: 5;
      }
    }
  }

  .text-item6 {
    bottom: 20%;
    left: 0;
    max-width: 28%;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line6.png") no-repeat center center;
        height: 184px;
        top: -95px;
        right: -42%;
      }
    }
  }

  .text-item7 {
    //bottom: 10%;
    //right: 1%;
    //max-width: 31%;
    bottom: 10%;
    right: 0;
    max-width: 32%;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line7.png") no-repeat center center;
        background-size: contain;
        height: 37px;
        bottom: -38px;
        left: -58%;
      }
    }
  }

  .text-item8 {
    bottom: 0;
    left: 27%;
    max-width: 32%;

    .why-block__text-item-content {
      &::before {
        background: url("../../../../public/assets/images/line8.png") no-repeat center center;
        top: -60px;
        left: 0;
      }
    }
  }


  @media (max-width: 1199px) {
    top: -20px;
    .container-card {
      padding: 0;
      max-width: 100%;
    }

    .card {
      padding: 32px 24px;

      &__info {
        &-title {
          font-size: 32px;
          line-height: 44px;
        }

        &-text {
          font-size: 14px;
          line-height: 22px;
          max-width: 290px;
        }

        &-form {
          max-width: 280px;
        }
      }
    }

    &__wrapper {
      margin-top: 64px;
      background: none;
    }

    &__content {
      padding: 0;
      display: block;

      &-title {
        position: initial;
      }

      &-image-wrapper {
        display: none;
      }
    }

    .text-item1,
    .text-item2,
    .text-item3,
    .text-item4,
    .text-item5,
    .text-item6,
    .text-item7,
    .text-item8 {
      position: initial;
      margin-bottom: 65px;

      .why-block__text-item-content {
        &::before {
          display: none;
        }
      }
    }

    &__list-top-image,
    &__list-bottom-image {
      display: block;
    }

    &__list-top {
      display: flex;
      &-texts {
        width: 55%;
        position: relative;
        z-index: 5;

        .text-item2,
        .text-item4,
        .text-item6,
        .text-item8 {
          max-width: none;
        }
      }

      &-image {
        width: 45%;
        transform: rotate(6.28deg);
        position: relative;
        top: -50px;
        .why-block__case-image {
          width: 530px;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 60px;
          left: -45px;
          width: 394px;
          height: 394px;
          border-radius: 50%;
          background: linear-gradient(180deg, rgba(157, 255, 146, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
          z-index: -1;
        }
      }
    }

    &__list-bottom {
      display: flex;
      flex-direction: row-reverse;
      &-texts {
        width: 55%;
        position: relative;
        z-index: 5;

        .text-item1,
        .text-item3,
        .text-item5,
        .text-item7 {
          max-width: none;
        }
      }

      &-image {
        width: 45%;
        transform: rotate(-14.38deg);
        position: relative;
        .why-block__case-image {
          width: 530px;
          position: absolute;
          right: 0;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 190px;
          right: -35px;
          width: 447px;
          height: 447px;
          border-radius: 50%;
          background: linear-gradient(180deg, rgba(157, 255, 146, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
          z-index: -1;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .container-blocks {
      padding: 0;
    }
    .card {
      padding: 49px 24px;
      flex-direction: column;

      &__info {
        max-width: 330px;
        text-align: center;

        &-title {
          font-size: 20px;
          line-height: 28px;
        }

        &-text {
          max-width: none;
        }

        &-form {
          max-width: 260px;
        }
      }
    }

    &__wrapper {
      background: url("../../../../public/assets/images/bg-circles-center.webp") no-repeat center center;
      background-size: contain;
    }

    &__content-title {
      padding: 0 16px;
      font-size: 48px;
      line-height: 56px;
    }

    &__content-image-wrapper {
      display: block;
      text-align: center;
      margin-bottom: 62px;
    }

    &__case-image {
      max-width: 325px;
      width: 100%;
      transform: rotate(6.78deg);
    }

    .text-item1,
    .text-item2,
    .text-item3,
    .text-item4,
    .text-item5,
    .text-item6,
    .text-item7,
    .text-item8 {
      max-width: 100%;
      margin-bottom: 32px;
      padding: 0 16px;
    }

    &__list-top-image,
    &__list-bottom-image {
      display: none;
    }

    &__list-top-texts,
    &__list-bottom-texts {
      width: 100%;
    }
  }
}
