@import "../../../public/assets/scss/variables";

.header {
  padding: 10px 0;
  position: relative;
  z-index: 1000;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background: rgba(230, 234, 245, 0.02);
    backdrop-filter: blur(15px);
    border-radius: 60px;
    border: 1px solid #E6EAF5;

    &-logo {
      display: flex;
      align-items: center;
      color: $primary-white;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.375rem;
      span {
        margin-left: 12px;
      }
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-button {
      .button {
        height: 43px;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: flex-start;
    list-style: none;
    color: $primary-white;
    margin: 0;
    padding: 0;

    &-item {
      margin-right: 1.5rem;
      transition: background-color 0.4s ease-in-out;
      border-radius: $border-radius-sm;
      a {
        padding: 12px 16px;
        display: block;
        color: $primary-white;
        text-decoration: none;
      }

      &:hover {
        background: $primary-green;
      }
    }
  }

  &__search {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $spacing-size-xxl;
    border: 1px solid $primary-green;
    border-radius: $border-radius-circle;
    cursor: pointer;

    .icon-search {
      width: 18px;
      height: 18px;
    }
  }

  &__mobile-action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      color: #fff;
    }
  }

  &__mobile-navigation {
    display: none;
    //background: $night-rider;
    //background: #242424;
    min-width: 312px;
    //height: 100vh;
    //text-align: right;
    position: absolute;
    z-index: 500;
    top: -10px;
    right: 0;
    transition: transform 0.3s ease-in-out;
    background: #000000A6;

    &--close {
      transform: translateX(calc(100% + 16px));
    }
    &--open {
      transform: translateX(16px);
    }

    &-wrapper {
      padding: 2.5rem 2rem;
      position: relative;
      z-index: 5000;

      background: rgba(230, 234, 245, 0.02);
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(15px);
      border-radius: 0px 0px 8px 8px;
    }
  }

  &__hamburger-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &__mobile-menu {
    display: none;
    margin-left: 24px;
  }

  &__mobile-button {
    margin: 60px 0;
    .button {
      width: 100%;
    }
  }

  &__mobile-divider {
    height: 1px;
    width: 100%;
    background: #424242;
  }

  .header__icon-svg {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }


  @media (max-width: 1199px) {
    &__container {
      align-items: center;
    }
  }

  @media (max-width: 1099px) {
    &__container-menu,
    &__container-search {
      display: none;
    }
    &__mobile-navigation {
      display: block;
    }
    &__mobile-menu {
      display: block;
    }
    &__menu {
      flex-direction: column;
      margin: 60px 0;
    }
  }

  @media (max-width: 600px) {
    &__container {
      padding: 12px 24px;
    }
    &__container-button {
      display: none;
    }

    &__container-logo {
      font-size: 17px;
      line-height: 16px;
      .logo {
        width: 31px;
        height: 31px;
      }
    }


  }
}
