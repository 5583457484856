.carousel-button-group {
  position: absolute;
  top: 10%;
  right: 5%;
  display: flex;
  align-items: center;
  gap: 32px;

  .carousel-button {
    cursor: pointer;

    &-icon {
      width: 24px;
      height: 24px;
    }

    &.disabled {
      .carousel-button-icon {
        fill: #0C0C0D73;
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: 600px) {
    top: 4%;
    gap: 24px;
  }
}

.react-multi-carousel-dot-list {
  bottom: 50px;

  @media (max-width: 1200px) {
    bottom: 30px;
  }

  @media (max-width: 600px) {
    bottom: 50px;
  }

}

.react-multi-carousel-dot {
  button {
    border: none;
    background: #D9D9D9;
    @media (max-width: 600px) {
      width: 10px;
      height: 10px;
    }
  }

  &--active {
    button {
      background: #2EC330;
    }
  }
}

.what-inside {
  padding-bottom: 190px;
  @media (max-width: 600px) {
    padding-bottom: 90px;
  }
}

.carousel-cases {
  &-item {
    height: 1024px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 2128.43px;
      height: 2699.25px;
      top: -800px;
      left: -1700px;
      transform: rotate(-102.93deg);
      background: linear-gradient(180deg, rgba(157, 255, 146, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
      border-radius: 46%;
      z-index: -1;
    }

    &__title {
      font-family: 'Nohemi', sans-serif;
      font-size: 64px;
      font-weight: 500;
      line-height: 184px;
    }

    &__content {
      display: flex;
      align-items: flex-end;
    }

    &__image {
    }

    &__text {
      font-size: 32px;
      line-height: 40px;

      span {
        font-family: 'Nohemi', sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
      }

    }

    .carousel-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .slide-1 {
    .carousel-cases-item {
      &__image {
        //width: 857px;
        height: 644px;
      }

      &__text {
        max-width: 570px;
      }
    }
  }

  .slide-2 {
    .carousel-cases-item {
      &__image {
        align-self: center;
        height: 585px;
        margin-left: 100px;
      }

      &__content {
        flex-direction: column;
        align-items: flex-start;
      }

      &__text {
        max-width: 830px;
      }
    }
  }

  .slide-3 {
    .carousel-cases-item {
      &__content {
        justify-content: center;
      }
    }
  }

  .slide-4 {
    .carousel-cases-item {
      &__content {
        justify-content: center;
      }
    }
  }

  .slide-5 {
    .carousel-cases-item {
      &__wrapper {
        display: flex;
      }

      &__content {
        flex-direction: column;
      }
    }

    .content-first {
      .carousel-cases-item__text {
        max-width: 680px;
        margin-top: 80px;
      }

      &.carousel-cases-item__content {
        align-items: flex-start;
        margin-top: 60px;
      }
    }

    .content-second {
      .carousel-cases-item__text {
        max-width: 680px;
      }
    }
  }

  @media (min-width: 1200px) {
    .slide-6 {
      .carousel-cases-item {
        &__title {
          position: relative;
          color: #ffffff;
          z-index: 10;
        }

        &__content {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .carousel-cases-item {
      height: 850px;

      &::before {
        left: -1800px;
      }

      &__text {
        font-size: 24px;
        line-height: 32px;

        span {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .slide-1 {
      .carousel-cases-item {
        &__image {
          align-self: center;
          //width: 673px;
          height: 430px;
        }

        &__content {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .slide-2 {
      .carousel-cases-item {
        &__text {
          max-width: 570px;
        }

        &__image {
          height: 430px;
        }
      }
    }

    .slide-3 {
      .carousel-cases-item {
        &__image {
          .carousel-image {
            max-height: 500px;
          }

        }
      }
    }

    .slide-5 {
      .content-first {
        width: 100%;

        &.carousel-cases-item__content {
          margin-top: 0;
        }

        .carousel-cases-item__image {
          align-self: center;
        }

        .carousel-cases-item__text {
          max-width: 570px;
        }
      }


      .content-second {
        display: none;
      }
    }

    .slide-5-2 {
      .carousel-cases-item {
        &__text {
          max-width: 570px;
        }

        &__content {
          flex-direction: column-reverse;
          align-items: flex-start;
        }

        &__image {
          align-self: center;
          height: 488px;
        }
      }
    }

    .slide-6 {
      .carousel-cases-item {
        &__content {
          justify-content: center;
        }
      }
    }
  }


  @media (max-width: 600px) {
    &-item {
      padding-bottom: 35px;
    }

    .carousel-cases-item {
      margin-bottom: 50px;
      height: 88%;
      display: flex;
      flex-direction: column;

      &::before {
        left: -2150px;
        top: -900px;
      }

      &__title {
        font-size: 48px;
        font-weight: 500;
        line-height: 56px;
        max-width: 225px;
        margin-bottom: 50px;
      }

      &__text {
        font-size: 20px;
        line-height: 28px;

        span {
          font-size: 20px;
          line-height: 28px;
        }
      }

      &__content {
        align-items: center;
        height: 100%;
      }
    }
    .slide-1 {
      .carousel-cases-item {
        margin-bottom: 50px;

        &__image {
          width: 100%;
          height: auto;
        }
      }
    }
    .slide-2 {
      .carousel-cases-item {
        &__image {
          margin: 0;
          height: auto;
        }
      }
    }
    .slide-3 {
      .carousel-cases-item {
        &__image {
          .carousel-image {
            height: auto;
          }
        }
      }
    }
    .slide-4 {
      .carousel-cases-item {
        &__image {
          .carousel-image {
            height: auto;
          }
        }
      }
    }
    .slide-5 {
      .content-first .carousel-cases-item__text {
        margin-top: 20px;
      }

      .carousel-cases-item {
        &__image {
          .carousel-image {
            height: 235px;
          }
        }
      }
    }
    .slide-5-2 {
      .carousel-cases-item {
        &__content {
          justify-content: flex-end;
        }

        &__image {
          height: auto;
        }
      }
    }
  }
}
