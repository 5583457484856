.modules-stats-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;

  .module {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;

    &_icon {
      width: 44px;
      height: 44px;
      fill: white;
      margin-bottom: 16px;
      &.green {
        fill: #479534;
      }
    }

    &_name {
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      text-align: center;
    }
  }

  @media (max-width: 600px) {
    .module {
      &_icon {
        width: 34px;
        height: 34px;
      }
      &_name {
        font-size: 14px;
      }
    }
  }
}
