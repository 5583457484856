@import "../../../public/assets/scss/variables";

.home-wrapper {
  height: calc(100vh + 100px);
  min-height: 950px;
  background: linear-gradient(0deg, rgba(10, 9, 8, 0.8), rgba(10, 9, 8, 0.8)), url("../../../public/assets/images/main-bg.jpeg");
  background-position: center center;
  background-size: cover;

  .home {
    overflow: hidden;
    position: relative;
  }

  .home-content {
    color: $primary-grayed;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-family: 'Nohemi', sans-serif;
      font-weight: 500;
      font-size: $font-size-xxl;
      line-height: 3.5rem;
      color: $primary-grayed-light;
      white-space: nowrap;
      position: relative;
      z-index: 2;
      margin-bottom: 0;
    }

    &__text {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: $font-size-lg;
      line-height: 2rem;
      margin-top: 32px;
    }

    &__image-wrapper {
      width: 50%;
      position: relative;
      left: -50px;
    }

    &__buttons {
      display: flex;
      gap: 20px;
      margin-top: 32px;

      .button {
        min-width: 160px;
        font-weight: 600;
      }
    }

    &__button-white {
      font-weight: 600;

      svg {
        margin-left: 4px;
      }
    }
  }

  .home-container {
    display: flex;
    padding-bottom: 20px;
  }

  @media (max-width: 1199px) {
    height: auto;
    .home-content {
      width: 100%;
      max-width: 490px;

      &__title {
        white-space: initial;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
      }

      &__image-wrapper {
        width: 100%;
        left: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .home-container {
      flex-direction: column-reverse;
      padding-bottom: 84px;
    }
  }

  @media (max-width: 600px) {
    .home-content {
      &__title {
        font-size: 48px;
        line-height: 56px;
        margin-top: 0;
      }

      &__text {
        font-size: 14px;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 32px;
      }

      &__image-wrapper {
        margin-top: 40px;
      }
    }
  }
}
