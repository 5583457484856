.containAll {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 100px;
  height: 100px;

  .containLoader {
    position: relative;
    height: 100px;
    width: 100px;
    margin: auto;
    z-index: 40;

    .circleGroup {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    @for $i from 1 through 8 {
      .circle-#{$i} {
        border: 7px solid #5ECA51;
        border-radius: 50%;
        box-sizing: border-box;
        border-right: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid transparent;
        -webkit-animation: rotate 1.2s cubic-bezier(0, .58, 1, .30) infinite;
        -moz-animation: rotate 1.2s cubic-bezier(0, .58, 1, .30) infinite;
        -o-animation: rotate 1.2s cubic-bezier(0, .58, 1, .30) infinite;
        animation: rotate 1.2s cubic-bezier(0, .58, 1, .30) infinite;

        -webkit-animation-delay: 30ms * $i;
        -moz-animation-delay: 30ms * $i;
        -o-animation-delay: 30ms * $i;
        animation-delay: 30ms * $i;
      }
    }
  }
}

@keyframes rotate {
  50% {
    border: 1px solid rgb(0, 255, 153);
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  100% {
    transform: rotatez(360deg);
  }
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
