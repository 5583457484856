.home-text {
  padding: 240px 0 220px;

  &__content {
    display: flex;
    align-items: flex-end;
    gap: 50px;
  }

  &__image-wrapper {
    width: 100%;
    max-width: 645px;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__info {
    &-title {
      font-family: "Nohemi", sans-serif;
      font-size: 48px;
      font-weight: 500;
      line-height: 56px;
      margin-bottom: 52px;
      max-width: 604px;
    }

    &-text {
      max-width: 535px;
    }
  }

  @media (max-width: 1200px) {
    padding: 50px 0 150px;
    &__content {
      flex-direction: column;
      align-items: baseline;
      gap: 32px;
    }

    &__image-wrapper {
      max-width: 100%;
    }

    &__info {
      &-title {
        max-width: none;
        margin-bottom: 42px;
      }
      &-text {
        max-width: none;
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 74px 0 120px;

    &__info {
      &-title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 32px;
      }
    }
  }
}
