*:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #444646;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #444646;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #444646;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #444646;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.form-input-container {
  width: 100%;
}

.form-input {
  position: relative;
  flex: 1 1 100%;

  &__input {
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(156, 220, 144, 0.7);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 100px;
    position: relative;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    color: #444646;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;


    &--disabled {
      background-color: #444646;
      border: none;
    }
  }
}
