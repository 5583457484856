@import "../../../public/assets/scss/variables.scss";

.button {
  height: 50px;
  border-radius: $border-radius-xxxl;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
  }

  //&--secondary {
  //  background-color: transparent;
  //  color: $primary-black;
  //  border: $spacing-size-xxxs solid $secondary-slate-gray;
  //  border-radius: $border-radius-xll;
  //  font-weight: $font-weight-md;
  //  font-size: $font-size-md;
  //  line-height: $spacing-size-xxl;
  //  text-decoration-line: underline;
  //  padding: $spacing-size-md $spacing-size-xxxl;
  //}

  &--primary {
    background-color: $primary-green;
    color: $primary-black;
  }

  &--white {
    background-color: $primary-grayed-light;
    color: $primary-black;
  }

  //&--primary-blue {
  //  background-color: $primary-blue;
  //  color: $primary-white;
  //}

  //&--main {
  //  background-color: $primary-black;
  //  color: $primary-white;
  //}

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  //&.active {
  //  background-color: $primary-lighter;
  //  color: $primary-white;
  //}

  &--transparent {
    //width: 100%;
    color: $primary-grayed;
    background-color: transparent;
    border: 1.25px solid $primary-grayed;
  }

  //&--direction {
  //  background-color: transparent;
  //  border-radius: 50px;
  //  border: 1px solid $primary-lighter;
  //  height: 30px;
  //  padding: 0 20px;
  //  font-weight: 500;
  //  font-size: 14px;
  //  line-height: 20px;
  //  color: $primary-light;
  //}
}
