@import "../fonts/font.scss";
@import "./grid.scss";
@import "./form-input.scss";
//@import "./mixins.scss";


// ********** Border Radius **********

$border-radius-none: 0;
$border-radius-sm: 0.25rem; // 4px
$border-radius-xxxl: 6.25rem; // 100px
$border-radius-circle: 50%;


// ********** Colors **********

$primary-white: #fff;
$primary-black: #0A0908;
$primary-dark: #0C0C0D;

$primary-green: #5ECA51;
$primary-green-light: #DBFFD7;
$primary-grayed: #B7B7B7;
$primary-grayed-light: #E6EAF5;


// ********** Spacings **********
$spacing-size-none: 0;
$spacing-size-xxl: 1.5rem; // 24px

// ********** Fonts **********

$font-size-base: 16px;
$line-height-base: 1.5;

$font-base: "Montserrat", sans-serif;

$font-size-m: 1.125rem; // 18px
$font-size-lg: 1.5rem; // 24px
$font-size-xxl: 4rem; // 48px
